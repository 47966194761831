<template>
  <el-container class="shopList">
    <el-header class="box add">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>企业温度传感器配置</el-breadcrumb-item>
        <el-breadcrumb-item>温度传感器列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-col :span="4" class="mr10">
        <el-form-item size="medium">
          <el-input
            placeholder="设备id"
            v-model="form3.device_id"
            class="input-with-select"
          >
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4" class="mr10">
        <el-form-item size="medium">
          <el-input
            placeholder="设备名称"
            v-model="form3.device_name"
            class="input-with-select"
          >
          </el-input>
        </el-form-item>
      </el-col>

      <el-col :span="4" class="mr10">
        <el-form-item size="medium">
          <el-autocomplete
            placeholder="企业名称"
            v-model="form3.company_name"
            :fetch-suggestions="querySearchAsync"
            clearable
            style="width: 60%; float: left"
            @select="handleSelect"
            @clear="form3.company_name='';form3.company_id='';search()"
          >
            <template slot-scope="{ item }">
              <span class="name">{{ item.company_name }}</span>
              <span
                class="addr"
                style="font-size: 12px; color: silver; float: right"
                >{{ item.credit_code }}</span
              >
            </template>
          </el-autocomplete>
        </el-form-item>
      </el-col>

      <el-col :span="6" class="tl mr10">
        <el-form-item size="medium">
          <el-button
            type="primary"
            @click="search"
            class="general_bgc general_border cfff"
            >查询</el-button
          >
          <el-button type="primary" size="medium" @click="Add"
            >新增配置</el-button
          >
          <el-button class size="medium" @click="$router.back()"
            >返回</el-button
          >
        </el-form-item>
      </el-col>
    </el-form>
    <el-table border :data="tableData" style="width: 100%">
      <el-table-column type="index" label="序号" width="55"> </el-table-column>
      <el-table-column prop="device_id" label="设备id" width="120">
      </el-table-column>
      <el-table-column prop="device_name" label="设备名称" width="120">
      </el-table-column>
      <el-table-column prop="company_name" label="所属企业" width="120">
      </el-table-column
      ><el-table-column prop="unit" label="单位" width="80"> </el-table-column
      ><el-table-column prop="max_value" label="最大值" width="60">
      </el-table-column
      ><el-table-column prop="min_value" label="最小值" width="60">
      </el-table-column>
      <el-table-column label="传感器图标" width="70">
        <template slot-scope="scope">
          <img
            :src="
              scope.row.device_icon
                ? scope.row.device_icon
                : 'http://cll20oss.foodtrace.org.cn/sab83jf36hyv7q8vfx5l.gif'
            "
            class="avatar-list"
          />
        </template>
      </el-table-column>
      <el-table-column prop="device_desc" label="公式绑定" width="160">
        <template slot-scope="scope">
          <div class="df aic" style="width: 100%">
            <div style="flex: 1" v-if="scope.row.formula_id">
              <div>
                {{ scope.row.formula_name }}
              </div>
              <div>
                {{ scope.row.formula }}
              </div>
              <div>
                <template v-if="scope.row.const_list">
                  <el-tag
                    style="margin-right: 10px; margin-bottom: 10px"
                    v-for="(item, index) in JSON.parse(scope.row.const_list)"
                    :key="'const' + index"
                    >{{ index }}:{{ item }}</el-tag
                  >
                </template>
                <template v-if="scope.row.sensor_list">
                  <el-tag
                    style="margin-right: 10px; margin-bottom: 10px"
                    type="success"
                    v-for="(item, index) in JSON.parse(scope.row.sensor_list)"
                    :key="'sensor' + index"
                    >{{ index }}:{{ item.name }}(默认值:{{
                      item.default ? item.default : 0
                    }}{{ item.unit }})</el-tag
                  >
                </template>
              </div>
            </div>
            <el-button
              type="text"
              class="disib"
              size="mini"
              v-if="!scope.row.formula_id"
              @click="
                formulaVisible = true;
                formulaForm.formulaSensorId = scope.row.id;
              "
              >绑定</el-button
            >
            <div class="df aic" style="flex-direction: column" v-else>
              <el-button
                type="text"
                class="disib"
                size="mini"
                @click="editBind(scope.row)"
                >修改</el-button
              >
              <el-button
                type="text"
                class="disib red"
                size="mini"
                style="margin-left: 10px"
                @click="cancelBind(scope.row)"
                >取消绑定</el-button
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            type="text"
            class="disib blue"
            size="mini"
            @click="handleVersion(scope.row)"
            >版本管理</el-button
          >
          <el-button
            type="text"
            class="disib blue"
            size="mini"
            @click="handleUpdate(scope.row)"
            >修改</el-button
          >
          <el-button
            type="text"
            class="disib red"
            size="mini"
            @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="i-page fr disib mt20">
      <el-pagination
        background
        :page-size="10"
        layout="total,prev, pager, next"
        :total="total"
        :current-page="currentPage"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogAddgsVisible"
      @close="closeDialogAddgsVisible"
      width="40%"
    >
      <el-form :model="form" label-position="right" label-width="200px">
        <el-form-item size="medium" label="设备编号id：">
          <el-input
            placeholder="设备编号id"
            v-model="form3.device_id"
            clearable
            style="width: 60%; float: left"
          >
          </el-input>
        </el-form-item>
        <el-form-item size="medium" label="设备名称：">
          <el-input
            placeholder="设备名称"
            v-model="form3.device_name"
            clearable
            style="width: 60%; float: left"
          >
          </el-input>
        </el-form-item>
        <el-form-item size="medium" label="企业名称：">
          <el-autocomplete
            placeholder="企业名称"
            v-model="form3.company_name"
            :fetch-suggestions="querySearchAsync"
            clearable
            style="width: 60%; float: left"
            @select="handleSelect"
          >
            <template slot-scope="{ item }">
              <span class="name">{{ item.company_name }}</span>
              <span
                class="addr"
                style="font-size: 12px; color: silver; float: right"
                >{{ item.credit_code }}</span
              >
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item size="medium" label="类型：">
          <el-input
            placeholder="类型"
            v-model="form3.type"
            clearable
            style="width: 60%; float: left"
          >
          </el-input>
        </el-form-item>
        <el-form-item size="medium" label="单位：">
          <el-input
            placeholder="单位"
            v-model="form3.unit"
            clearable
            style="width: 60%; float: left"
          >
          </el-input>
        </el-form-item>
        <el-form-item size="medium" label="最大值：">
          <el-input
            placeholder="最大值"
            v-model="form3.max_value"
            clearable
            style="width: 60%; float: left"
          >
          </el-input>
        </el-form-item>
        <el-form-item size="medium" label="最小值：">
          <el-input
            placeholder="最小值"
            v-model="form3.min_value"
            clearable
            style="width: 60%; float: left"
          >
          </el-input>
        </el-form-item>
        <el-form-item size="medium" label="默认定位：">
          <div class="df aic">
            <div v-if="chooseMap" style="margin-right: 10px">
              {{ chooseMap.address }}
            </div>
            <el-popover
              placement="right"
              width="800"
              trigger="click"
              v-model="showPopover"
            >
              <div class="df" style="width: 100%; flex-direction: column">
                <div style="flex: 0 0 60px; padding: 0 20px" class="df aic">
                  <el-autocomplete
                    v-model.trim="address"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入详细地址"
                    style="width: 100%"
                    :trigger-on-focus="false"
                    @select="handleMapSelect"
                  />
                </div>
                <div style="flex: 0 0 380px; position: relative">
                  <div
                    style="
                      position: absolute;
                      left: 385px;
                      top: 150px;
                      z-index: 9999;
                    "
                  >
                    <img
                      src="@/assets/image/location.png"
                      alt=""
                      style="width: 30px; height: 30px"
                    />
                  </div>
                  <baidu-map
                    id="allmap"
                    :zoom="zoom"
                    :mapClick="false"
                    @click="getLocation"
                    @ready="mapReady"
                    :scroll-wheel-zoom="true"
                    @dragging="mapMoving"
                    @zoomend="zoomend"
                  >
                  </baidu-map>
                </div>
                <div
                  style="flex: 0 0 80px; padding: 0 20px"
                  v-if="surroundingPois.length"
                >
                  <el-tag
                    style="margin: 5px; cursor: pointer"
                    v-for="item in surroundingPois"
                    :key="item.uid"
                    @click="gotoPoint(item)"
                    >{{ item.title }}</el-tag
                  >
                </div>
                <div
                  style="
                    flex: 0 0 60px;
                    justify-content: flex-end;
                    padding: 0 20px;
                  "
                  class="df aic"
                >
                  <el-button type="primary" @click="chooseGPS"
                    >现在当前位置</el-button
                  >
                </div>
              </div>
              <el-button slot="reference">选择定位</el-button>
            </el-popover>
          </div>
        </el-form-item>
        <el-form-item size="medium" label="图片：">
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :http-request="uploadSectionFile"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item size="medium">
          <el-button type="primary" size="medium" @click="submit">{{
            handleName
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="绑定公式"
      :visible.sync="formulaVisible"
      @close="closeFormulaVisible"
      width="40%"
    >
      <el-form
        ref="formulaForm"
        :model="formulaForm"
        label-width="100px"
        style="width: 60%"
      >
        <el-form-item size="medium" label="绑定公式">
          <div class="df aic">
            <el-autocomplete
              v-model="formulaForm.formulaKey"
              :fetch-suggestions="formulaQuerySearchAsync"
              placeholder="请输入公式名称"
              @select="handleFormulaSelect"
            >
              <template slot-scope="{ item }">
                <span class="name">{{ item.formulaName }}</span>
                <span
                  class="addr"
                  style="font-size: 12px; color: silver; float: right"
                  >传感器数量:{{ item.sensorNum }}</span
                >
              </template>
            </el-autocomplete>
            <div style="margin-left: 10px; flex: 1">
              <el-input
                v-if="formulaForm.formula"
                type="text"
                style="width: 100%"
                v-model="formulaForm.formula"
                disabled
              ></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          size="medium"
          :label="'sensor' + (o - 1)"
          v-for="o in formulaForm.sensorNum"
          :key="'sensor' + o"
        >
          <div class="df aic">
            <el-autocomplete
              v-model="formulaForm.sensorArr[o - 1]['name']"
              :fetch-suggestions="
                (queryString, cb) => sensorQuerySearchAsync(queryString, cb)
              "
              placeholder="请绑定对应传感器"
              @select="(item) => handleSensorSelect(item, o - 1)"
            >
              <template slot-scope="{ item }">
                <span class="name">{{ item.deviceName }}</span>
                <span
                  class="addr"
                  style="font-size: 12px; color: silver; float: right"
                  >{{ item.companyName }}</span
                >
              </template>
            </el-autocomplete>
            <div style="margin-left: 10px; flex: 1">
              <el-input
                type="number"
                v-if="formulaForm.sensorArr[o - 1]['unit']"
                placeholder="请输入传感器默认值"
                v-model="formulaForm.sensorArr[o - 1]['default']"
              >
                <template slot="append">{{
                  formulaForm.sensorArr[o - 1]["unit"]
                }}</template>
              </el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          size="medium"
          :label="'num' + (o - 1)"
          v-for="o in formulaForm.constNum"
          :key="'num' + o"
        >
          <el-input
            type="number"
            v-model="formulaForm.constArr[o - 1]['num' + o]"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formulaVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFormula">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="版本管理"
      :visible.sync="versionVisible"
      @close="closeFormulaVisible"
      width="40%"
    >
      <div style="padding: 20px">
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-col :span="6" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="版本名称"
                v-model="versionQuery.keyword"
                class="input-with-select"
                clearable
                @clear="
                  versionQuery.page = 1;
                  getVersionData();
                "
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="tl mr10">
            <el-form-item size="medium">
              <el-button
                type="primary"
                @click="
                  versionQuery.page = 1;
                  getVersionData();
                "
                class="general_bgc general_border cfff"
                >查询</el-button
              >
              <el-popover
                placement="bottom"
                width="400"
                trigger="click"
                v-model="popoverVisible"
              >
                <div style="padding: 20px">
                  <el-form ref="form" :model="versionForm" label-width="70px">
                    <el-form-item label="版本名称" prop="versionName">
                      <el-input
                        v-model="versionForm.versionName"
                        style="width: 80%"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="上传文件" prop="version">
                      <el-upload
                        style="width: 80%"
                        class="upload-demo"
                        action="#"
                        :http-request="
                          (params) => {
                            uploadVersionFile(params);
                          }
                        "
                        :on-remove="removeVersionFile"
                        :limit="1"
                        :file-list="versionForm.version"
                      >
                        <el-button size="small" type="primary"
                          >点击上传</el-button
                        >
                        <div slot="tip" class="el-upload__tip">
                          只能上传bin文件，且不超过1M
                        </div>
                      </el-upload>
                    </el-form-item>
                    <el-form-item prop="version">
                      <div
                        style="width: 80%; justify-content: flex-end"
                        class="df aic"
                      >
                        <el-button>重置</el-button>
                        <el-button type="primary" @click="createNewVersion"
                          >新增</el-button
                        >
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
                <el-button
                  style="margin-left: 10px"
                  type="primary"
                  slot="reference"
                  size="medium"
                  >新增配置</el-button
                >
              </el-popover>
            </el-form-item>
          </el-col>
        </el-form>
        <div>
          <el-table :data="versionData" style="width: 100%">
            <el-table-column
              type="index"
              label="序号"
              width="55"
            ></el-table-column>
            <el-table-column
              prop="versionName"
              label="版本名称"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="url"
              label="文件地址"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="lastInfo"
              label="更新信息"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="lastTime"
              label="最后更新时间"
              width="180"
            ></el-table-column>

            <el-table-column prop="device_type" label="操作" width="180">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  class="disib blue"
                  size="mini"
                  @click="pushVersion(scope.row)"
                  >使用该版本</el-button
                >
                <el-button
                  type="text"
                  class="disib red"
                  size="mini"
                  @click="versionDelete(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div
            class="i-page df disib mt20"
            style="justify-content: flex-end"
            v-if="versionTotal"
          >
            <el-pagination
              background
              :page-size="10"
              layout="total,prev, pager, next"
              :total="versionTotal"
              :current-page="versionQuery.page"
              @current-change="versionCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import api from "../../../util/extra-api";
import { local } from "@/util/util";
// import { session, aa } from "../../../util/util";
export default {
  data() {
    return {
      formulaForm: {
        formulaKey: "",
        formula: "",
        constNum: null,
        sensorNum: null,
        sensorArr: [],
        constArr: [],
        formulaId: null,
        formulaSensorId: null,
      },
      formulaVisible: false,
      tableData: [],
      allCompany: [],
      form: {
        company_name: "",
        pager_offset: "0",
        pager_openset: "10",
        credit_code: "",
        signDepartment: "",
      },
      form3: {
        device_id: "",
        device_name: "",
        company_id: "",
        company_name: "",
        type: "",
        unit: "",
        max_value: "",
        min_value: "",
        pager_offset: "0",
        pager_openset: "10",
      },
      imageUrl: "",
      handleName: "添加",
      title: "新增企业温度传感器配置",
      currentPage: 1,
      allCurrentPage: 1,
      total: 0,
      allTotal: 0,
      dialogAddgsVisible: false,
      companyId: [],
      versionVisible: false,
      versionForm: {
        versionName: "",
        version: [],
      },
      versionTotal: 0,
      versionQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        deviceId: null,
      },
      versionData: [],
      popoverVisible: false,
      BMap: null,
      map: null,
      myGeo: null,
      searchJingwei: "",
      mapSearchInfo: {
        location: "成都",
        keyword: "西华大学",
      },
      address: "",
      coordinate: "",
      mapInfo: {
        lng: 104.074994,
        lat: 30.640429,
      },
      chooseMap: null,
      surroundingPois: [],
      showPopover: false,
      zoom: 20,
    };
  },
  mounted() {
    this.getData(this.form);
  },
  watch: {
    popoverVisible(newVal) {
      if (!newVal) {
        this.versionForm.versionName = "";
        this.versionForm.version = [];
      }
    },
  },
  methods: {
    editBind(row) {
      for (let key in JSON.parse(row.sensor_list)) {
        let data = JSON.parse(row.sensor_list)[key];
        this.formulaForm.sensorArr.push({
          default: data.default,
          name: data.name,
          key: data.id,
          unit: data.unit,
        });
      }
      for (let key in JSON.parse(row.const_list)) {
        let data = {};
        let num = Number(key.slice(3)) + 1;
        data["num" + num] = JSON.parse(row.const_list)[key];
        this.formulaForm.constArr.push(data);
      }
      this.formulaForm.constNum = this.formulaForm.constArr.length;
      this.formulaForm.sensorNum = this.formulaForm.sensorArr.length;
      this.formulaForm.formula = row.formula;
      this.formulaForm.formulaKey = row.formula_name;
      this.formulaForm.formulaId = row.formula_id;
      this.formulaVisible = true;
      this.formulaForm.formulaSensorId = row.id;
      console.log(this.formulaForm, 123123);
    },
    zoomend() {
      // console.log("修改", this.mapInfo, this.map);
      if (this.mapInfo && this.map) {
        this.map.setCenter(new BMap.Point(this.mapInfo.lng, this.mapInfo.lat));
        let that = this;
        this.myGeo.getLocation(this.mapInfo, function (result) {
          that.surroundingPois = result.surroundingPois;
        });
      }
    },
    chooseGPS() {
      let that = this;
      this.myGeo.getLocation(this.mapInfo, function (result) {
        that.surroundingPois = [];
        that.chooseMap = {
          address: result.address,
          point: result.point,
        };
        // console.log(result);
      });
      this.showPopover = false;
      this.mapInfo = {
        lng: 104.074994,
        lat: 30.640429,
      };
      this.address = "";
    },
    gotoPoint(item) {
      let that = this;
      this.mapInfo = item.point;
      // console.log(item.point);
      this.map.setCenter(new BMap.Point(item.point.lng, item.point.lat));
      this.myGeo.getLocation(item.point, function (result) {
        that.surroundingPois = result.surroundingPois;
      });
    },
    mapMoving(data) {
      console.log(data.point.lng, data.point.lat);
      let that = this;
      this.mapInfo = data.point;
      this.myGeo.getLocation(data.point, function (result) {
        that.surroundingPois = result.surroundingPois;
      });
    },
    handleMapSelect(item) {
      var { point } = item;
      let that = this;
      this.coordinate = point;
      this.map.setCenter(new BMap.Point(item.point.lng, item.point.lat));
      this.mapInfo = item.point;
      this.myGeo.getLocation(point, function (result) {
        that.surroundingPois = result.surroundingPois;
        that.chooseMap = {
          address: result.surroundingPois[0].address,
          point: result.surroundingPois[0].point,
        };
      });
    },

    querySearch(queryString, cb) {
      var that = this;
      var myGeo = new this.BMap.Geocoder();
      myGeo.getPoint(
        queryString,
        function (point) {
          if (point) {
            that.coordinate = point;
          } else {
            that.coordinate = null;
          }
        },
        this.locationCity
      );
      var options = {
        onSearchComplete: function (results) {
          if (local.getStatus() === 0) {
            // 判断状态是否正确

            var s = [];
            for (var i = 0; i < results.getCurrentNumPois(); i++) {
              var x = results.getPoi(i);
              var item = { value: x.address + x.title, point: x.point };
              s.push(item);
              cb(s);
            }
          } else {
            cb();
          }
        },
      };
      var local = new this.BMap.LocalSearch(this.map, options);
      local.search(queryString);
    },
    mapReady({ BMap, map }) {
      this.point = new BMap.Point(this.mapInfo.lng, this.mapInfo.lat); // 选择一个经纬度作为中心点
      map.centerAndZoom(this.point, 20); //设置地图中心点和缩放级别
      this.BMap = BMap;
      this.myGeo = new BMap.Geocoder();
      let that = this;
      this.myGeo.getLocation(this.mapInfo, function (result) {
        // console.log(result, 123);
        if (result && result.surroundingPois) {
          that.surroundingPois = result.surroundingPois;
        }
      });
      this.map = map;
      console.log(
        new BMap.Point(this.mapInfo.lng, this.mapInfo.lat),
        this.mapInfo,
        1111111
      );
      // this.map.setCenter(new BMap.Point(this.mapInfo.lng, this.mapInfo.lat));
      this.map.setCenter(this.mapInfo);
      this.mapInfo;
    },
    getLocation(e) {
      console.log(e);
      let that = this;
      // this.map.setCenter({ lng: e.point.lng, lat: e.point.lat });
      this.map.setCenter(new BMap.Point(e.point.lng, e.point.lat));
      this.myGeo.getLocation(e.point, function (result) {
        // console.log(result, 123);
        that.surroundingPois = result.surroundingPois;
      });
    },
    removeVersionFile() {
      this.versionForm.version = [];
    },
    createNewVersion() {
      api
        .post("/v1/wx/device/addVersion", {
          versionName: this.versionForm.versionName,
          url: this.versionForm.version[0].url,
          deviceId: this.versionQuery.deviceId,
        })
        .then((res) => {
          if (res.code === 200) {
            this.popoverVisible = false;
            this.getVersionData();
          }
        });
    },
    versionDelete(row) {
      api.post("/v1/wx/device/deleteVersion", { id: row.id }).then((res) => {
        if (this.versionData.length === 1 && this.versionQuery.page > 1) {
          this.versionQuery.page--;
        }
        this.getVersionData();
      });
    },
    versionCurrentChange(page) {
      this.versionQuery.page = page;
      this.getVersionData();
    },
    getVersionData() {
      api.get("/v1/wx/device/versionList", this.versionQuery).then((res) => {
        if (res.code === 200) {
          this.versionData = res.data;
          this.versionTotal = res.total;
        }
      });
    },
    uploadVersionFile(params) {
      const file = params.file,
        fileType = file.type,
        isBin = fileType === "application/octet-stream",
        isLt1M = file.size / 1024 / 1024 < 1.5;
      console.log(file, file.size / 1024 / 1024);
      // 这里常规检验，看项目需求而定
      if (!isBin) {
        this.$message.error("只能上传bin文件!");
        return false;
      }
      if (!isLt1M) {
        this.$message.error("只能上传文件大小小于1M");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", 1117);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("文件上传失败，请重新上传");
        } else {
          this.$set(this.versionForm, "version", [
            {
              name: resp.name,
              url: resp.url,
            },
          ]);
        }
      });
    },
    pushVersion(row) {
      api.post("/v1/wx/device/renewal", { id: row.id }).then((res) => {
        if (res.code == 200) {
          this.$message.success("推送成功,请三分钟后刷新查看更新结果");
        }
      });
    },
    handleVersion(row) {
      this.versionQuery.deviceId = row.id;
      this.getVersionData();
      this.versionVisible = true;
    },
    cancelBind(row) {
      api
        .post("/v1/wx/formula/cancelBind", {
          deviceId: row.id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.getData(this.form);
          }
        });
    },
    submitFormula() {
      let constList = {},
        sensorList = {};
      this.formulaForm.constArr.forEach((v, i) => {
        constList["num" + i] = Number(v["num" + (i + 1)]);
      });
      this.formulaForm.sensorArr.forEach((v, i) => {
        sensorList["sensor" + i] = {
          id: v["sensor" + i],
          name: v.name,
          default: v.default,
          unit: v.unit,
        };
      });
      // console.log(this.formulaForm, 1111);
      api
        .post("/v1/wx/formula/bind", {
          constList,
          sensorList,
          deviceId: this.formulaForm.formulaSensorId,
          formulaId: this.formulaForm.formulaId,
        })
        .then((res) => {
          if (res.code === 200) {
            this.formulaVisible = false;
            this.getData(this.form);
          }
        });
    },
    handleSensorSelect(item, index) {
      this.formulaForm.sensorArr[index]["sensor" + index] = item.id;
      this.formulaForm.sensorArr[index]["name"] = item.deviceName;
      this.formulaForm.sensorArr[index]["unit"] = item.unit;
    },
    formulaQuerySearchAsync(queryString, cb) {
      if (queryString) {
        console.log(queryString);
        api
          .get(`/v1/wx/formula/list`, {
            keyword: queryString,
          })
          .then((res) => {
            if (res.code == 200) {
              let results = res.data;
              // console.log(results);
              cb(results);
            }
          })
          .catch((err) => {
            cb([]);
          });
      } else {
        cb([]);
      }
    },
    sensorQuerySearchAsync(queryString, cb) {
      if (queryString) {
        api
          .get(`/v1/wx/formula/selectSensor`, {
            keyword: queryString,
          })
          .then((res) => {
            if (res.code == 200) {
              let results = res.data;
              // console.log(results);
              cb(results);
            }
          })
          .catch((err) => {
            cb([]);
          });
      } else {
        cb([]);
      }
    },
    handleFormulaSelect(item) {
      console.log(item);
      this.formulaForm.formulaKey = item.formulaName;
      this.formulaForm.formula = item.formula;
      this.formulaForm.formulaId = item.id;
      for (let i = 0; i < item.constNum; i++) {
        this.formulaForm.constArr.push({});
      }
      for (let i = 0; i < item.sensorNum; i++) {
        this.formulaForm.sensorArr.push({ name: "" });
        this.formulaForm.sensorArr[i]["sensor" + i] = null;
      }
      console.log(this.formulaForm);
      this.formulaForm.constNum = item.constNum;
      this.formulaForm.sensorNum = item.sensorNum;
    },
    closeFormulaVisible() {
      this.formulaForm = {
        formulaKey: "",
        formula: "",
        constNum: null,
        sensorNum: null,
        sensorArr: [],
        constArr: [],
        formulaId: null,
        formulaSensorId: null,
      };
    },
    uploadSectionFile(params) {
      console.log(params, 123);
      const file = params.file,
        fileType = file.type,
        isImage = fileType.indexOf("image") != -1,
        isLt10M = file.size / 1024 / 1024 < 10;

      if (!this.form3.company_id) {
        this.$message.error("请先选择企业");
      }

      // 这里常规检验，看项目需求而定
      if (!isImage) {
        this.$message.error("只能上传图片格式png、jpg、gif!");
        return;
      }
      if (!isLt10M) {
        this.$message.error("只能上传图片大小小于10M");
        return;
      }
      const fileFormData = new FormData();
      // fileFormData.append("file", file);
      fileFormData.append("file", file);
      fileFormData.append("company_id", this.form3.company_id);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("图片上传失败，请重新上传");
        } else {
          this.imageUrl = resp.url;
        }
      });
    },
    getData() {
      let {device_id,
        device_name,
        company_id,
        type,
        unit,
        max_value,
        min_value,
        pager_offset,
        pager_openset}=this.form3
        let data={
          device_id,
        device_name,
        company_id,
        type,
        unit,
        max_value,
        min_value,
        pager_offset,
        pager_openset
        }
      axios.get("/pc/company-device/list", data).then((res) => {
        res = res.data;
        if (res) {
          this.tableData = res.company_device_list;
          this.total = res.company_device_total;
        }
      });
    },
    //根据库房名称查询信息
    querySearchAsync(queryString, cb) {
      if (queryString) {
        axios.get("/pc/company/signList/2", {company_name:this.form3.company_name}).then((response) => {
          let results = response.data.company_list;
          cb(results);
        });
      } else {
        cb([]);
      }
    },
    search() {
      this.form3.pager_offset = "0";
      this.form3.pager_openset = "10";
      this.getData();
    },
    handleUpdate(item) {
      console.log(item, item.location);
      this.form3.id = item.id;
      this.form3.device_name = item.device_name;
      this.form3.device_id = item.device_id;
      this.form3.company_name = item.company_name;
      this.form3.company_id = item.company_id;
      this.form3.unit = item.unit;
      this.form3.type = item.type;
      this.form3.max_value = item.max_value;
      this.form3.min_value = item.min_value;
      if (item.location) {
        this.chooseMap = JSON.parse(item.location);
        this.mapInfo = this.chooseMap.point;
      }
      this.imageUrl = item.device_icon
        ? item.device_icon
        : "http://cll20oss.foodtrace.org.cn/sab83jf36hyv7q8vfx5l.gif";
      this.dialogAddgsVisible = true;
      this.handleName = "修改";
    },
    handleSelect(item) {
      this.form3.company_id = item.company_id;
      this.form3.company_name = item.company_name;
    },
    Add() {
      this.dialogAddgsVisible = true;
      this.form3.company_name = "";
      this.form3.company_id = "";
      this.form3.device_name = "";
      this.form3.device_id = "";
      this.form3.unit = "";
      this.form3.type = "";
      this.form3.max_value = "";
      this.form3.min_value = "";
      this.handleName = "添加";
    },
    handleDelete(row) {
      let { user_id } = row;
      console.log(row);
      axios.delete(`/pc/company-device/delete/${row.id}`).then((res) => {
        if (res.code === 200) {
          this.getData(this.form);
        }
      });
    },
    submit() {
      //默认传感器图片 http://cll20oss.foodtrace.org.cn/sab83jf36hyv7q8vfx5l.gif

      if (!this.form3.id) {
        let data = {
          ...this.form3,
          device_icon: this.imageUrl,
        };
        if (!this.imageUrl) {
          data.device_icon =
            "http://cll20oss.foodtrace.org.cn/sab83jf36hyv7q8vfx5l.gif";
        }
        if (this.chooseMap) {
          console.log(this.chooseMap);
          data.location = JSON.stringify(this.chooseMap);
        }
        axios.post("/pc/company-device/add", data).then((res) => {
          this.dialogAddgsVisible = false;
          this.form3.company_name = "";
          this.form3.company_id = "";
          this.form3.device_id = "";
          this.form3.device_name = "";
          this.form3.unit = "";
          this.form3.type = "";
          this.form3.max_value = "";
          this.form3.min_value = "";
          this.getData(this.form3);
        });
      } else {
        let data = {
          ...this.form3,
          device_icon: this.imageUrl,
        };
        if (this.chooseMap) {
          data.location = JSON.stringify(this.chooseMap);
        }
        axios.post("/pc/company-device/update", data).then((res) => {
          this.dialogAddgsVisible = false;
          this.form3.company_name = "";
          this.form3.device_id = "";
          this.form3.device_name = "";
          this.form3.company_id = "";
          this.form3.id = "";
          this.form3.unit = "";
          this.form3.type = "";
          this.form3.max_value = "";
          this.form3.min_value = "";
          this.getData(this.form3);
        });
      }
    },
    closeDialogAddgsVisible() {
      this.dialogAddgsVisible = false;
      this.chooseMap = null;
      this.mapInfo = {
        lng: 104.074994,
        lat: 30.640429,
      };
      this.address = "";
    },
    currentChange(page) {
      this.currentPage = page;
      this.form3.pager_offset = String((page - 1) * 10);
      this.getData(this.form);
    },
    allCurrentChange(page) {
      this.allCurrentPage = page;
      this.form2.pager_offset = String((page - 1) * 10);
      this.getAllData(this.form2);
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader {
  display: flex;
  // justify-content: flex-start;
  /deep/.el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
}
.avatar-uploader {
  /deep/.el-upload:hover {
    border-color: #409eff;
  }
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.avatar-list {
  width: 100px;
  height: 100px;
  display: block;
}

.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    top: 0px;
    left: 30px;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }
  .list_tab {
    padding: 10px;
    background-color: #f5f7fa;
    .list_tag {
      right: -10px;
      padding: 2px 6px;
      border-radius: 10px;
      top: -10px;
    }
  }
  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/.el-table .aa .cell {
    display: flex;
    justify-content: center;
  }
}
.aic {
  align-items: center;
}
#allmap {
  width: 100%;
  height: 100%;
}
</style>
